<style lang="less">
.tGroupPerson {

    .form {
        position: relative;
    }

    .ivu-modal {
        width: 900px !important;

        .ivu-tabs-bar {
            margin-bottom: 15px !important;
        }
    }
}
</style>
<template>
    <Modal v-model="show" :title="editTitle" class="tGroupPerson" :mask-closable="false">
        <Form ref="tGroupPersonForm" :model="tGroupPersonForm" :rules="tGroupPersonFormRule" :label-width="120"
              class="form">
<!--            <Tabs class="tabs">-->
<!--                <TabPane label="基本信息">-->
                  <Row>
                      <Col span="12">
                        <FormItem label="缴费方式">
                          <Select :disabled="disabled" transfer v-model="tGroupPersonForm.isMarry" placeholder="缴费方式">
                            <Option value="现金">现金</Option>
                            <Option value="微信">微信</Option>
                            <Option value="支付宝">支付宝</Option>
                            <Option value="余额">余额</Option>
                            <Option value="其他">其他</Option>
                          </Select>
                        </FormItem>
                      </Col>
                  </Row>
                  <Row>
                    <Col span="12">
                      <FormItem label="缴费金额" prop="personName">
                        <Input :disabled="disabled" type="text" :maxlength=50
                               v-model="tGroupPersonForm.personName" placeholder="缴费金额"/>
                      </FormItem>
                    </Col>
                  </Row>
                    <Row>
                        <Col span="12">
                            <FormItem label="是否开发票" prop="personName">
<!--                                <Input :disabled="disabled" type="text" :maxlength=50 v-model="tGroupPersonForm.personName" placeholder="缴费方式"/>-->
                              <Select :disabled="disabled" transfer v-model="tGroupPersonForm.isMarry" placeholder="是否开发票">
                                <Option value="是">是</Option>
                                <Option value="否">否</Option>
                              </Select>
                            </FormItem>
                        </Col>
                    </Row>
<!--                </TabPane>-->
<!--                <Button @click="readIdCard" :loading="readShow" type="primary" icon="md-add" slot="extra">读取二代身份证</Button>-->
<!--            </Tabs>-->
            <Spin size="large" fix v-if="spinShow"></Spin>
        </Form>
        <div slot="footer">
            <Button type="text" @click="show=false">取消</Button>
            <Button type="primary" :loading="loading" @click="handelSubmit" v-if="!disabled">确认结算</Button>
        </div>
<!--        <chooseWorkType v-model="workShow" @workCodeReback="workCodeReback"></chooseWorkType>-->
        <!--危害因素-->
        <harmDrawer v-model="harmShow" :modalTitle="title" :selectedHarmList="selectedHarmList" @harmReback="harmReback"
                    :selectType="seltType"></harmDrawer>
    </Modal>
</template>
<script>
import * as groupPersonApi from '@/api/healthy/tGroupPerson'
import {gethazardFactorsByGroupId} from "../../../api/healthy/tCombo"
import {getTOrderGroupByGroupOrderId,getTOrderGroupByGroupUnitId,queryTOrderGroupList} from "../../../api/healthy/tOrderGroup"
import util from "../../../libs/util";
import {formatDate} from "../../../api/tool";
import harmDrawer from "../tCombo/harmDrawer";
import {queryAllTGroupOrderList} from "../../../api/healthy/tGroupOrder";
import {getDictDataByType} from "../../../api";
import {queryAllTGroupUnitList} from "../../../api/healthy/tGroupUnit";

export default {
    name: "addPayment",
    components: {
         harmDrawer
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
       groupOrderId:{
         type: String
       },
        tGroupPersonId: {
            type: String
        },
        modalTitle: {
            type: String
        },
        orderId: {
            type: String,
            default: ""
        },
        groupId: {
            type: String,
            default: ""
        },
        groupName: {
            type: String,
            default: ""
        }
    },
    data() {
        // 自定义验证 年龄
        const validAge = (rule, value, callback) => {
            if (/[^0-9]/ig.test(value) || !value) {
                callback(new Error('请输入正确的年龄！'));
            }
            /* else if (parseInt(value) < 16) {
                callback(new Error('输入的年龄不能小于16周岁！'))
            } */
            else {
                callback();
            }
        };
        //自定义验证工龄
        const validYear = (rule, value, callback) => {
            if (/[^0-9]/ig.test(value) || !value) {
                callback(new Error('请输入正确的工龄！'));
            } else {
                callback();
            }
        };

        return {
          title: "",
          seltType: "",
          selectedHarmList: [],
          harmShow: false,
          workShow: false,
          spinShow: false,
          show: this.value,
          editTitle: this.modalTitle,
          loading: true,
          disabled: false,
          industryTypeCodeArr: [],//行业类别
          econoTypeCodeArr: [],//经济类别
          workTypeCodeArr: [],//工种
          workStateCodeArr: [],//在岗状态
          tGroupPersonForm: {
            personName: '',
            sex: '',
            idCard: '',
            birth: '',
            age: '',
            isMarry: '',
            mobile: '',
            dept: '',
            workNum: '',
            workYear: '',
            workMonth: '',
            exposureWorkYear: '',
            exposureWorkMonth: '',
            workName: '',
            workStateCode: '',
            workStateText: "",
            exposureStartDate: '',
            workTypeCode: '',
            workTypeText: '',
            jcType: '1',
            orderId: "",
            groupId: "",
            hazardFactors: "",
            hazardFactorsText: "",
            physicalType: "",
            otherHazardFactors: ""
          },
          tGroupPersonFormRule: {
            personName: [
              {required: true, message: '人员姓名不能为空！', trigger: 'blur'},
              {type: 'string', max: 50, message: '数据的最大长度为50！', trigger: 'blur'}
            ],
            sex: [
              {required: true, message: '性别不能为空！', trigger: 'blur'},
              {type: 'string', max: 50, message: '数据的最大长度为50！', trigger: 'blur'}
            ],
          }
        }
    },
    methods: {
        //接害时间改变
        expDateChange(e) {
            this.tGroupPersonForm.exposureStartDate = e;
        },
        //读取身份证信息
        readIdCard() {
            this.readShow = true;
            this.connect();
        },
        //连接身份证读卡器
        connect() {
            let _this = this;

            function onSuccess(data) {
                if (data.match("\"errorMsg\" : \"(.*?)\"")[1] == "打开成功") {
                    _this.readCert();
                } else {
                    _this.readShow = false;
                    _this.$Message.error("身份证读卡器连接失败！");
                }
            }

            function onError(data) {
                _this.readShow = false;
                _this.$Message.error("请安装身份证读卡器服务！");
            }

            let options = new Object();
            options.type = "GET";
            options.url = "http://127.0.0.1:19196/OpenDevice" + "&" + "t=" + Math.random();		//URL后面加一个随机参数的目的是为了防止IE内核浏览器的数据缓存
            options.timeout = 5000;
            options.onSuccess = onSuccess;
            options.onError = onError;
            _this.ajax(options);
        },
        //获取身份证信息
        readCert() {
            let _this = this;

            function onSuccess(data) {
                if (data.match("\"errorMsg\" : \"(.*?)\"")[1].indexOf("读卡成功") != -1) {
                    let date = new Date();
                    let yearfull = date.getFullYear();
                    let age = 0;
                    let idCard = data.match("\"certNumber\" : \"(.*?)\"")[1];
                    if (idCard.length == 18) {
                        let birthyear = idCard.substring(6, 10);
                        age = yearfull - birthyear;
                    }
                    if (idCard.length == 15) {
                        let birthDay = "";
                        birthDay = idCard.substring(6, 12);
                        birthDay = "19" + birthDay;
                        birthDay = birthDay.substring(0, 4);
                        age = yearfull - birthDay;
                    }
					let monthBirth = idCard.substring(10,12);
					let dayBirth = idCard.substring(12,14);
					let monthNow = date.getMonth() + 1;
					let dayNow = date.getDate();
					if(monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)){
						age--;
					}
                    _this.tGroupPersonForm.idCard = data.match("\"certNumber\" : \"(.*?)\"")[1];
                    _this.tGroupPersonForm.personName = data.match("\"partyName\" : \"(.*?)\"")[1];
                    _this.tGroupPersonForm.sex = data.match("\"gender\" : \"(.*?)\"")[1];
                    _this.tGroupPersonForm.age = age;
                    _this.tGroupPersonForm.isPass = 1;

					_this.tGroupPersonForm.re = _this.physicalType;
                    let birth = _this.getBirthDate(idCard);
                    //出生日期
                    _this.tGroupPersonForm.birth = birth;
                    _this.readShow = false;
                } else {
                    _this.readShow = false;
                    _this.$Message.error("身份证信息读取失败！")
                }
            }

            let options = new Object();
            options.type = "GET";
            options.url = "http://127.0.0.1:19196/readcard" + "&picpath=\"D:\\1\\123.bmp\"" + "&" + "t=" + Math.random();	//URL后面加一个随机参数的目的是为了防止IE内核浏览器的数据缓存
            options.timeout = 5000;
            options.onSuccess = onSuccess;
            _this.ajax(options);
        },
        disconnect() {
            function onSuccess(data) {

            }

            this.initFrom();
            let options = new Object();
            options.type = "GET";
            options.url = "http://127.0.0.1:19196/CloseDevice" + "&" + "t=" + Math.random();	//URL后面加一个随机参数的目的是为了防止IE内核浏览器的数据缓存
            options.timeout = 5000;
            options.onSuccess = onSuccess;
            this.ajax(options);
        },
        ajax(options) {
            if (options.type == null) {
                options.type = "POST";
            }

            if (options.url == null) {
                options.url = "";
            }

            if (options.timeout == null) {
                options.timeout = 5000;
            }

            if (options.onComplate == null) {
                options.onComplate = function () {
                }
            }

            if (options.onError == null) {
                options.onError = function () {
                }
            }

            if (options.onSuccess == null) {
                options.onSuccess = function () {
                }
            }

            if (options.data) {
                options.data = "";
            }

            let xml;
            if (typeof ActiveXObject != 'undefined') {
                let aVersions = ["Microsoft.XMLHTTP", "Msxml2.XMLHttp.6.0", "Msxml2.XMLHttp.5.0", "Msxml2.XMLHttp.4.0", "Msxml2.XMLHttp.3.0"];
                for (let i = 0; i < aVersions.length; i++) {
                    try {
                        xml = new ActiveXObject(aVersions[i]);
                    } catch (e) {
                    }
                }
            } else if (typeof XMLHttpRequest != 'undefined') {
                xml = new XMLHttpRequest();
            }
            xml.open(options.type, options.url, true);
            let timeoutLength = options.timeout;
            let requestDone = false;
            setTimeout(function () {
                requestDone = true;
            }, timeoutLength);
            xml.onreadystatechange = function () {
                if (xml.readyState == 4 && !requestDone) {
                    if (httpSuccess(xml)) {
                        options.onSuccess(httpData(xml));
                    } else {
                        options.onError();
                    }
                    options.onComplate();
                    xml = null;
                }
            };
            xml.send();

            function httpSuccess(r) {
                try {
                    return !r.status && location.protocol == "file:"
                        ||
                        (r.status >= 200 && r.status <= 300)
                        ||
                        r.status == 304
                        ||

                        navigator.userAgent.indexOf("Safari") >= 0
                        && typeof r.status == "undefined";
                } catch (e) {
                }
                return false;
            }

            function httpData(r) {
                let ct = r.responseType;
                if (ct) {
                    if (ct == "script") {
                        eval.call(window, data);
                    }
                    if (ct == "xml") {
                        return r.responseXML;
                    }
                    if (ct == "json") {
                        return JSON.parse(r.responseText);
                    }
                }
                return r.responseText;
            }
        },
        handleHarmSelect() {
            this.selectedHarmList = [];
            this.harmShow = true;
            this.title = "危害因素选择";
            this.seltType = "checkbox";
            if (this.tGroupPersonForm.hazardFactors) {
                let ids = this.tGroupPersonForm.hazardFactors;
                let txts = this.tGroupPersonForm.hazardFactorsText.split("|");
                for (let i = 0; i < ids.length; i++) {
                    let id = ids[i];
                    let txt = txts[i];
                    this.selectedHarmList.push({
                        typeCode: id,
                        typeName: txt
                    });
                }
            } else {
                this.selectedHarmList = [];
            }
        },
        harmReback(e) {
            if (e && e.length > 0) {
                let ids = [], txts = [];
                e.forEach(i => {
                    ids.push(i.typeCode);
                    txts.push(i.typeName);
                })
                this.tGroupPersonForm.hazardFactors = ids.join("|");
                this.tGroupPersonForm.hazardFactorsText = txts.join("|");
            }
        },
        handleWorkCode() {
            this.workShow = true;
        },
        workCodeReback(v) {
            if (v && v.length > 0) {
                this.tGroupPersonForm.workTypeCode = v[0].value;
                this.tGroupPersonForm.workTypeText = v[0].title;
                this.$forceUpdate();
            }
        },
        //在岗状态改变
        workChange(v) {
            if (v) {
                this.tGroupPersonForm.workStateText = v.label.trim();
            }
        },
        //根据身份证号计算出生日期
        getBirthDate(IDCard) {
            //获取身份证号的年、月、日
            var year = IDCard.substring(6, 10);
            var month = IDCard.substring(10, 12);
            var day = IDCard.substring(12, 14);
            //拼接成出生日期
            var birthDate = year + '-' + month + '-' + day;
            return birthDate;
        },
        idCardChange(e) {
            if (e) {
                let idCard = e.trim();
                if (idCard && idCard.length == 18) {
                    //出生日期
                    let birth = this.getBirthDate(idCard);
                    this.tGroupPersonForm.birth = birth;
                    //性别 倒数第二位
                    let sex = idCard.substr(16, 1);
                    if (sex % 2 == 0) {
                        this.tGroupPersonForm.sex = "女";
                    } else {
                        this.tGroupPersonForm.sex = "男";
                    }
                    //年龄
                    let b = new Date(birth);
                    let c = new Date();
                    let age = c.getFullYear() - b.getFullYear();
                    if (c.getMonth() < b.getMonth() || (c.getMonth() == b.getMonth() && c.getDate() < b.getDate())) {
                        age--;
                    }
                    this.tGroupPersonForm.age = age;
                }
            } else {
                this.tGroupPersonForm.age = null;
                this.tGroupPersonForm.sex = null;
                this.tGroupPersonForm.birth = null;
            }
        },
        //关闭弹框
        closeModal(val) {
            this.$emit('input', val);
        },
        //获取类型库树
        async getProTypeByTypeCode(typeCode) {
            let data = await groupPersonApi.getTypeCodeByTProType({typeCode: typeCode}).then(res => res.data);
            this.recursion(data);
            return data;
        },
        //递归
        recursion(proTypes) {
            if (proTypes) {
                proTypes.forEach(item => {
                    item.value = item.typeCode;
                    item.title = item.typeName;
                    item.selected = false;
                    item.checked = false;
                    if (item.children && item.children.length > 0) {
                        item.disabled = true;
                        this.recursion(item.children)
                    }
                })
            }
        },
        //初始化表单
        initForm() {
            this.queryOrderData();//查询订单
            this.tGroupPersonForm = {
                personName: '',
                sex: '',
                idCard: '',
                birth: '',
                age: '',
                isMarry: '',
                mobile: '',
                dept: '',
                workNum: '',
                workYear: '',
                workMonth: '',
				registDate:null,
                exposureWorkYear: '',
                exposureWorkMonth: '',
                workName: '',
                workStateCode: '',
                workStateText: '',
                exposureStartDate: '',
                workTypeCode: '',
                workTypeText: '',
                jcType: '1',
                orderId: "",
                groupId: "",
                hazardFactors: "",
                hazardFactorsText: "",
                physicalType: "",
                otherHazardFactors: "",
                nation:"",
                tolerable:this.$hospitalName.importFailure
            };
        },
        //分组改变
        groupChange(e){
            if(e){
                if(e.value){
                    if(this.tGroupPersonForm.isPass && this.tGroupPersonForm.isPass != 1){
                        this.$Modal.confirm({
                            title: '确认修改',
                            content: '体检人员已登记，请谨慎修改分组！',
                            loading: true,
                            onOk: () => {
                                this.$Modal.remove();
                                this.tGroupPersonForm.oldGroupId = e;
                                this.loading = false;
                                this.hazardFactorUpdate(e);
                            },
                            onCancel: () => {
                                this.tGroupPersonForm.groupId = this.tGroupPersonForm.oldGroupId;
                                this.$Message.info('取消了当前的操作行为！');
                                this.loading = false;
                            }
                        });
                    }else{
                        this.hazardFactorUpdate(e);
                    }
                }
            }
        },
        //危害因素自动同步
        hazardFactorUpdate(e){
            let _this = this;
            if(e.tag){
                if((this.physicalType=="职业体检" || this.physicalType=="放射体检") && e.tag.name && e.tag.name.toString().trim().length > 0){
                    let nameSplit = [];
                    nameSplit = e.tag.name.split("[");
                    if(nameSplit.length>0){
                        let nameSplitTwo = nameSplit[1].split("]");
                        let workStateText = nameSplitTwo[0];
                        if(workStateText){
                            let workStateNow = this.workStateCodeArr.filter(item => item.title && item.title.indexOf(workStateText) > -1);
                            if(workStateNow && workStateNow.length>0){
                                this.tGroupPersonForm.workStateCode = workStateNow[0].value;
                                this.tGroupPersonForm.workStateText = workStateNow[0].title;
                            }
                        }
                    }
                }
                if((this.physicalType=="职业体检" || this.physicalType=="放射体检") && e.tag.hazardFactorCodes && e.tag.hazardFactorCodes.length>0 && e.tag.hazardFactorTexts && e.tag.hazardFactorTexts.length>0){
                    let ids = e.tag.hazardFactorCodes;
                    let txts = e.tag.hazardFactorTexts;
                    _this.tGroupPersonForm.hazardFactors = ids.join("|");
                    _this.tGroupPersonForm.hazardFactorsText = txts.join("|");
                    if (_this.tGroupPersonForm.hazardFactors) {
                        for (let i = 0; i < ids.length; i++) {
                            let id = ids[i];
                            let txt = txts[i];
                            _this.selectedHarmList.push({
                                typeCode: id,
                                typeName: txt
                            });
                        }
                    } else {
                        _this.selectedHarmList = [];
                    }
                }
            }
        },
        //提交
        handelSubmit() {
            let _this = this;
            if (this.tGroupPersonForm.workStateCode == '1001' && !this.tGroupPersonForm.exposureStartDate) {
                this.tGroupPersonForm.exposureStartDate = formatDate(new Date(), "yyyy-MM-dd");
            }
            this.$refs['tGroupPersonForm'].validate((valid) => {
                if (valid) {
                    _this.loading = true;
                    if (_this.physicalType == '职业体检' || _this.physicalType == '放射体检') {
                        if(_this.tGroupPersonForm.hazardFactors && _this.tGroupPersonForm.hazardFactors.indexOf("|")<=-1 && typeof _this.tGroupPersonForm.hazardFactors == "object"){
                            _this.tGroupPersonForm.hazardFactors = _this.tGroupPersonForm.hazardFactors.join("|");
                        }
                        _this.tGroupPersonForm.hazardFactorsText = _this.tGroupPersonForm.hazardFactorsText.trim();
                        _this.tGroupPersonForm.workStateText = _this.tGroupPersonForm.workStateText.trim();
                    } else {
                        _this.tGroupPersonForm.hazardFactors = null;
                    }
                    if(_this.tGroupPersonForm.registDate){
						_this.tGroupPersonForm.registDate = formatDate(_this.tGroupPersonForm.registDate) ;
                    }
                    if (_this.tGroupPersonId != null && _this.tGroupPersonId.trim().length > 0) {
						_this.tGroupPersonForm.id = _this.tGroupPersonId;
						_this.tGroupPersonForm.oldGroupId = _this.tGroupPersonForm.groupId;
                        // groupPersonApi.updateTGroupPerson(this.tGroupPersonForm).then(res => {
                        groupPersonApi.updateTGroupPersonAndGroup(this.tGroupPersonForm).then(res => {
                            if (res.code == 200) {
                                this.closeModal(false);
                                this.$emit('handleSearch');
                                this.$Message.success('保存成功');
                            } else {
                                this.$Message.error(res.msg);
                            }
                        }).finally(() => {
                            this.loading = false;
                        })
                    } else {
                        this.tGroupPersonForm.physicalType = this.physicalType;
                        groupPersonApi.addTGroupPerson(this.tGroupPersonForm).then(res => {
                            if (res.code == 200) {
                                this.closeModal(false);
                                this.$emit('handleSearch');
                                this.$Message.success('保存成功');
                            } else {
                                this.$Message.error(res.msg);
                            }
                        }).finally(() => {
                            this.loading = false;
                        })
                    }
                } else {
                    this.loading = false;
                    this.$Message.error('表单验证不通过！');
                }
            });
        },
        hazardFactorsChange() {
            let labels = this.$refs.hazardFactors.$children[0].values;
            if (labels && labels.length > 0) {
                this.tGroupPersonForm.hazardFactorsText = "";
                labels.forEach((i, index) => {
                    if (index == labels.length - 1) {
                        this.tGroupPersonForm.hazardFactorsText += i.label;
                    } else {
                        this.tGroupPersonForm.hazardFactorsText += i.label + "|";
                    }
                })
            }
        },
        getGroup(orderId){
            // getTOrderGroupByGroupOrderId({groupOrderId:orderId}).then(res =>{
            queryTOrderGroupList({groupOrderId:orderId}).then(res =>{
                if (res.success) {
                    this.groupList = res.data;
                }
            })
        },
        getGroupByUnitId(unitId){
            getTOrderGroupByGroupUnitId({groupUnitId:unitId}).then(res =>{
                if (res.success) {
                    this.groupList = res.data;
                }
            })
        },
        //查询已确认的健康体检订单
        queryOrderData() {
            queryAllTGroupOrderList({physicalType: this.physicalType, payStatus: 1}).then(res => {
                if (res.success) {
                    this.orderArr = res.data;
                }
            });
        },
        //查询单位名称
        queryAllTGroupUnitList(){
            queryAllTGroupUnitList({
                physicalType: this.physicalType
            }).then(res => {
                if (res.success) {
                    this.unitArr = res.data;
                }
            });
        },
        onOrderSelectChange(e) {
            if (e) {
               /* this.tGroupPersonForm.orderId = e.value;*/
                this.tGroupPersonForm.dept = e.label;
                this.tGroupPersonForm.unitId = e.tag;
                /*this.tGroupPersonForm.groupId = "";*/
                // this.getGroupByUnitId(e.tag);
               /* this.getGroup(e.value);*/
            }
        },
    },
    computed: {
        physicalType() {
            return this.$store.state.theme.theme.physicalType;
        },
    },
    mounted() {
        this.tGroupPersonForm.physicalType = this.physicalType;
		//民族

        // this.queryOrderData();
        this.queryAllTGroupUnitList();
    },
    watch: {
        value(val) {
            this.show = val;
        },
        async show(val) {
            this.initForm();
            this.loading = false;
            this.editTitle = this.modalTitle;
            this.tGroupPersonForm.groupId = this.groupId;
            this.tGroupPersonForm.orderId = this.orderId;
			this.tGroupPersonForm.registDate = this.registDate;
            this.disabled = this.editTitle == "查看" ? true : false;

            if (val) {
            	let _this = this;
				_this.getGroup(this.tGroupPersonForm.orderId);
                if (_this.tGroupPersonForm.groupId && !_this.tGroupPersonId) {
                    gethazardFactorsByGroupId({groupId: this.tGroupPersonForm.groupId}).then(res => {
                        if (res.success) {
                            let arr = [];
                            for (let i = 0; i < res.data.length; i++) {
                                arr.push(res.data[i].hazardFactors);
                                this.tGroupPersonForm.hazardFactorsText += "|" + res.data[i].hazardFactorsText;
                            }
                            this.tGroupPersonForm.hazardFactors = arr;
                            this.tGroupPersonForm.hazardFactorsText = this.tGroupPersonForm.hazardFactorsText.substring(1);
                            /*if(this.groupList.length == 0){
                                this.getGroupByUnitId(this.tGroupPersonForm.unitId);
                            }*/
                        }
                    })
                }
                if (_this.tGroupPersonId != null && _this.tGroupPersonId.trim().length > 0) {
                    groupPersonApi.getTGroupPerson({id: _this.tGroupPersonId}).then(res => {
                        if (res.code == 200) {
							_this.tGroupPersonForm = res.data;
							_this.tGroupPersonForm.registDate = res.data.registDate;
                            if(_this.tGroupPersonForm && _this.tGroupPersonForm.hazardFactors){
								_this.tGroupPersonForm.hazardFactors = _this.tGroupPersonForm.hazardFactors.split("|");
                            }
                            /*if(this.groupList.length == 0){
                                this.getGroupByUnitId(this.tGroupPersonForm.unitId);
                            }*/
                        } else {
							_this.$Message.error(res.msg);
                        }
                    });
                } else {
                    if (this.physicalType == '职业体检' || this.physicalType == '放射体检') {
                        getDictDataList({dictId: '1456812385326206976',}).then(res => {
                            if (res.success) {
                                this.data = res.data;
                                for (let i = 0; i < this.data.length; i++) {
                                    if (this.data[i].title == "其他") {
                                        this.tGroupPersonForm.workTypeCode = this.data[i].value;
                                        this.tGroupPersonForm.workTypeText = this.data[i].title;
                                        this.tGroupPersonForm.workName = "其他操作工";
                                        break;
                                    }
                                }
                            }
                        })
                    } else if (this.physicalType == '健康体检' || this.physicalType == '从业体检') {
                        this.tGroupPersonForm.jcType = "";
                    }
                }
                if (this.physicalType == '职业体检' || this.physicalType == '放射体检') {
                    this.workStateCodeArr = await util.getDictionaryData("workState");
                    this.hazardFactorsArr = await this.getProTypeByTypeCode("TYPE_001");
                    if (this.tGroupPersonId == "" && this.tGroupPersonId.trim().length == 0) {
                        for (let i = 0; i < this.workStateCodeArr.length; i++) {
                            if (this.workStateCodeArr[i].title == this.groupName) {
                                this.tGroupPersonForm.workStateCode = this.workStateCodeArr[i].value;
                                this.tGroupPersonForm.workStateText = this.workStateCodeArr[i].title;
                            }
                        }
                    }
                }
            } else {
                this.closeModal(val);
            }
        }
    }
}
</script>
